import { createLocalizedPathnamesNavigation, Pathnames } from 'next-intl/navigation';
import { ALLOWED_LOCALES, LOCALE_PREFIX } from './constants/locale';

export const { Link, redirect, usePathname, useRouter } =
    createLocalizedPathnamesNavigation({
        locales: ALLOWED_LOCALES,
        pathnames: {
            '/': '/',
            // '/experts': '/experts',
            // '/experts/[expertId]': '/experts/[expertId]',
            // '/news': '/news',
            // '/privacy-policy': '/privacy-policy',
            // '/[userId]': '/[userId]',
            // '/[userId]/[...slug]': '/[userId]/[...slug]',
        } satisfies Pathnames<typeof ALLOWED_LOCALES>,
        localePrefix: LOCALE_PREFIX
    });
