import React from 'react';
import { Pagination, PaginationProps } from 'antd';

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev' || type === 'next') {
        return null;
    }

    return originalElement;
};

export const CustomPagination = (props: PaginationProps) => (
    <Pagination
        rootClassName="b-pagination"
        itemRender={itemRender}
        defaultCurrent={1}
        showTitle={false}
        {...props}
    />
);
