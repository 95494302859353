import { GeneralFilter, ExpertsData, ExpertDetails } from '../types/experts';
import { apiRequest } from './helpers';

export const getExpertsList = (locale: string, filter?: GeneralFilter): Promise<ExpertsData> => apiRequest({
    url: '/home/coachsearch1',
    method: 'post',
    data: { ...filter },
    locale
});

export const getExpertById = (id: string, locale: string): Promise<ExpertDetails> => apiRequest({
    url: '/home/coachdetails',
    method: 'post',
    data: { id },
    locale
});
